<template>
  <div class="document-category">
    <div class="search-wrapper">
      <a-form-model
        :model="entity"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input
            v-model="entity.name"
            type="text"
            placeholder="输入搜索"
            allowClear
            @change="
              () => {
                if (entity.name == '') delete entity.name;
              }
            "
          ></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="documentCategoryService"
        :entity="entity"
        :filters="filters"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleSortClick(action.record)"
            >排序</a-button
          >
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <a-button type="link" @click="handleDeleteClick(action.record)"
            >删除</a-button
          >
        </template>
      </y-table>
    </div>

    <a-drawer :visible="visible" width="50%" @close="handleClose">
      <a-form-model :model="documentCategory" @submit.prevent="handleSave">
        <a-form-model-item
          label="名称"
          prop="name"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input v-model="documentCategory.name" allowClear></a-input>
        </a-form-model-item>

        <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input-number
            v-model="documentCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
          <a-space>
            <a-button htmlType="submit" :loading="loading" type="primary">
              保存
            </a-button>
            <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <a-modal
      :confirm-loading="loading"
      title="排序"
      :visible="visibleSortModal"
      @cancel="handleSortModalCancel"
      @ok="handleSortModalOk"
    >
      <a-form-model :model="documentCategory">
        <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input-number
            v-model="documentCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import DocumentCategoryService from "@/services/document-category";

const defaultDocumentCategory = {};

export default {
  data() {
    return {
      documentCategoryService: DocumentCategoryService,
      entity: JSON.parse(JSON.stringify(defaultDocumentCategory)),
      filters: {},
      visible: false,
      visibleSortModal: false,
      loading: false,
      columns: [
        {
          dataIndex: "id",
          title: "ID",
        },
        {
          dataIndex: "name",
          title: "名称",
          sorter: true,
        },
        {
          dataIndex: "sequence",
          title: "权重",
          sorter: true,
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      documentCategory: {},
    };
  },
  methods: {
    async handleSearch() {
      this.entity = JSON.parse(JSON.stringify(this.entity));
    },
    handleClickAdd() {
      this.documentCategory = JSON.parse(
        JSON.stringify(defaultDocumentCategory)
      );
      this.visible = true;
    },
    handleClose() {
      this.documentCategory = JSON.parse(
        JSON.stringify(defaultDocumentCategory)
      );
      this.visible = false;
    },
    handleSortClick(record) {
      this.documentCategory = record;
      this.visibleSortModal = true;
    },
    handleEditClick(record) {
      this.documentCategory = record;
      this.visible = true;
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await DocumentCategoryService.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(defaultDocumentCategory));
      }
    },
    handleSortModalCancel() {
      this.documentCategory = JSON.parse(
        JSON.stringify(defaultDocumentCategory)
      );
      this.visibleSortModal = false;
    },
    async handleSortModalOk() {
      try {
        this.loading = true;

        await DocumentCategoryService.updateEntity({
          id: this.documentCategory.id,
          sequence: this.documentCategory.sequence,
        });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.documentCategory = {};
        this.visibleSortModal = false;
        this.entity = JSON.parse(JSON.stringify(defaultDocumentCategory));
      }
    },
    async handleSave() {
      try {
        this.loading = true;
        if (!this.documentCategory.id) {
          await DocumentCategoryService.addEntity(this.documentCategory);
        } else {
          await DocumentCategoryService.updateEntity(this.documentCategory);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.documentCategory = {};
        this.entity = JSON.parse(JSON.stringify(this.entity));
        this.visible = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.document-category {
}
</style>