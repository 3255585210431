import request from "../libs/request";
import EntityService from "./entity";

class DocumentCategoryService extends EntityService {
    constructor(name) {
        super(name);
    }

    /**
 * 获取Entity分页
 * @param {*} entities
 * @param {*} pageable
 * @returns
 */
    async getEntityPage(entities, pageable) {
        const result = await request({
            url: `/document-categories`,
            method: "get",
            params: {
                ...entities,
                ...pageable,
            },
        });

        return result;
    }
}

export default new DocumentCategoryService("document-category");
